.firstscreen .search_action .results {

  & .airport {
    box-sizing: border-box;
    width: 33.33%;
    height: 185px;
    padding: 17px 0 17px 24px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-width: 0 1px 1px 0;
    cursor: pointer;

    & b {
      background: #FBA820;
      font-weight: normal;
    }

    & .container {
      overflow: hidden;
      margin-bottom: 7px;

      & .name {
        font: 21px/22px Inter;
        letter-spacing: -0.04em;
        color: #000;
      }

      & .location {
        margin-top: 4px;
        font: 14px/14px Inter;
        letter-spacing: -0.02em;
        color: #000;
      }
    }

    & .iata,
    & .icao {
      font: 400 14px/20px Inter;
      letter-spacing: -0.02em;
      color: #B9332B;
    }
  }

  & .last {
    border-width: 0 0 1px 0;
  }
}


@media (max-width: 700px) {

  .firstscreen .search_action .results .airport {
    padding: 12px 16px 16px 16px;
  }
}

@media (max-width: 500px) {

  .firstscreen .search_action .results {

    & .last {
      border-right-width: 1px;
    }

    & .airport {
      width: 50%;
    }
  }
}
