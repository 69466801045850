// Медиазапросы сделаны через  mixin'ы, потому что на странице информации о рейсе блоки отображаются в мобильной версии
// Соотвественно, это позволяет избежать дублирование стилей в исходниках

.flights .results .results-item {
  margin-top: 35px;
  transform: skew(-3.5deg);
  border-radius: 16px;
  background: #FFFFFF;
  cursor: pointer;

  & .results-item-content {
    display: flex;
    transform: skew(3.5deg);
    padding: 11px 45px 11px 11px;

    & .results-item-content-mask {
      display: flex;
      justify-items: center;
      width: 208px;
      height: 208px;
      overflow: hidden;
      transform: skew(-3.5deg);
      border-radius: 16px;

      & .results-item-content-photo {
        transform: skew(3.5deg);
        min-width: 228px;
        height: 208px;
        margin: 0 -10px;
        z-index: -1;

        & .skeleton {
          height: 208px;
        }
      }
    }

    & .results-item-content-mask-back, .results-item-content-mask-there {
      height: 208px;
      width: 222px;
      margin-left: -215px;
      z-index: 5;
    }

    & .results-item-content-mask-there {
      background: url(../assets/left-mask-arrow.svg) left center/auto 100% no-repeat;
    }

    & .results-item-content-mask-back {
      background: url(../assets/right-mask-arrow.svg) right center/auto 100% no-repeat;
    }

    & .results-item-content-conditions {
      margin-left: 30px;

      & .results-item-content-conditions-plane-name {
        margin-top: 12px;
        margin-right: -250px;
        font: 800 40px/52px Inter;
        letter-spacing: -0.04em;

        & .skeleton {
          width: 250px;
          height: 40px;
        }
      }

      & .results-item-content-conditions-plane-type, .results-item-content-conditions-plane-el {
        font: 500 14px/15px Inter;
        letter-spacing: 0.15em;
        color: #7F7E77;
        text-transform: uppercase;
        margin-top: 8px;

        & .skeleton {
          width: 170px;
          height: 15px;
        }
      }

      & .results-item-content-conditions-plane-el {
        display: none;
      }

      & .results-item-content-conditions-warning {
        width: 108px;
        margin-top: 14px;
        padding: 4px 8px;
        border-radius: 4px;
        background: #E95A52;
        font: 600 11px/14px Inter;
        letter-spacing: -0.02em;
        color: #4E1818;
        text-align: center;
      }

      & .results-item-content-conditions-nowarning {
        height: 36px;
      }

      & .results-item-content-conditions-directions {
        display: flex;
        margin-top: 11px;

        & .results-item-content-conditions-directions-warning {
          color: #E95A52;
        }

        & .results-item-content-conditions-directions-to {
          margin-left: 45px;
        }

        & .results-item-content-conditions-directions-name {
          font: 600 16px/14px Inter;
          letter-spacing: -0.03em;

          & .skeleton {
            width: 70px;
            height: 16px;
          }
        }

        & .results-item-content-conditions-directions-details {
          margin-top: 8px;
          font: 400 16px/21px Inter;
          letter-spacing: -0.03em;

          & .skeleton {
            width: 70px;
            height: 42px;
          }
        }
      }

      & .results-item-content-conditions-mobile {
        display: none;
      }
    }

    & .results-item-content-options {
      margin: 25px 0 0 auto;

      & .results-item-content-options-main {
        display: flex;
        justify-content: flex-end;

        & .results-item-content-options-main-price {
          font: 700 27px/22px Inter;
          letter-spacing: -0.04em;

          & .skeleton {
            position: relative;
            top: -3px;
            width: 200px;
            height: 30px;
          }
        }

        & .results-item-content-options-main-favorites {
          margin: -5px 0 0 20px;
        }
      }

      & .results-item-content-options-plane {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 73px;
        font: 16px/22px Inter;
        letter-spacing: -0.02em;
        color: #808080;

        & .results-item-content-options-plane-photo {
          text-align: right;
          height: 75px;

          & .skeleton {
            width: 250px;
            height: 75px;
          }
        }

        & .results-item-content-conditions-splitter {
          display: none;
        }

        & .results-item-content-options-plane-year {
          margin-left: 20px;

          & .skeleton {
            width: 50px;
          }
        }

        & .results-item-content-options-plane-seat {
          margin-left: 20px;

          & .skeleton {
            width: 50px;
          }
        }
      }
    }
  }

  & .results-item-content-mobile-infoblock {
    display: none;
  }
}

.flights .results .results-item-unselected {
  background-color: #F9F7F1;
}

@mixin width-1090 {

  .results-item-content {
    padding-right: 20px;

    .results-item-content-options {
      margin-top: 19px;

      & .results-item-content-options-main {
        display: flex;
        justify-content: flex-end;

        & .results-item-content-options-main-price {
          font: 700 24px/22px Inter;

          & .skeleton {
            width: 170px;
            height: 24px;
          }
        }

        & .results-item-content-options-main-favorites {
          margin: -5px 0 0 20px;
          height: 29px;
        }
      }

      .results-item-content-options-plane {
        flex-wrap: wrap-reverse;
        margin-top: 14px;

        & .results-item-content-conditions-splitter {
          display: block;
        }

        & .results-item-content-options-plane-seat {
          margin-left: 0;
        }

        .results-item-content-options-plane-photo {
          margin-top: 44px;
          width: 100%;

          & .skeleton {
            width: 250px;
            height: 44px;
          }
        }
      }
    }

    & .results-item-content-conditions {
      margin-left: 20px;

      & .results-item-content-conditions-plane-name {
        margin-top: 24px;
        margin-right: -100px;
        font: 800 27px/32px Inter;
        max-height: 32px;
        overflow: hidden;

        & .skeleton {
          width: 200px;
          height: 32px;
        }
      }

      & .results-item-content-conditions-plane-type, .results-item-content-conditions-plane-el {
        margin-top: 12px;
        margin-right: -100px;
      }

      & .results-item-content-conditions-warning {
        margin-top: 33px;
      }

      & .results-item-content-conditions-nowarning {
        height: 55px;
      }

      & .results-item-content-conditions-directions {
        margin-right: -150px;

        & .results-item-content-conditions-directions-name {
          font: 600 14px/14px Inter;

          & .skeleton {
            height: 14px;
          }
        }

        & .results-item-content-conditions-directions-details {
          margin-top: 4px;
          font: 400 14px/18px Inter;

          & .skeleton {
            width: 70px;
            height: 36px;
          }
        }
      }
    }
  }
}

@mixin width-900 {

  .results-item-content {

    & .results-item-content-options {

      & .results-item-content-options-main .results-item-content-options-main-favorites {
        margin-left: 5px;
      }

      & .results-item-content-options-plane .results-item-content-options-plane-photo {

      }
    }

    & .results-item-content-mask {
      width: 154px;

      & .results-item-content-photo {
        min-width: 174px;

        & .skeleton {
          height: 174px;
        }
      }
    }

    & .results-item-content-mask-there {
      width: 174px;
      margin-left: -164px;
    }

    & .results-item-content-mask-back {
      width: 174px;
      margin-left: -164px;
    }
  }
}


@mixin width-760 {

  & .results-item-content {

    & .results-item-content-mask {
      display: none;
    }

    & .results-item-content-mask-back, .results-item-content-mask-there {
      display: none;
    }
  }
}

@mixin width-700 {

  margin: 0;
  padding: 0;
  transform: skew(0deg);
  border-radius: 0;

  & .results-item-content {
    box-sizing: border-box;
    height: 100%;
    transform: skew(0deg);
    margin: 0 16px;
    padding: 12px 0;
    border: 0 solid #F2F2F2;
    border-bottom-width: 1px;

    & .results-item-content-mask {
      display: block;
      width: 65px;
      height: 65px;
      border-radius: 10px;

      & .results-item-content-photo {
        display: block;
        min-width: 105%;
        height: 65px;

        & .skeleton {
          height: 65px;
        }
      }
    }

    & .results-item-content-mask-back, .results-item-content-mask-there {
      display: block;
      height: 65px;
      width: 70px;
      margin-left: -67px;
      z-index: 5;
    }

    & .results-item-content-conditions {
      margin-left: 10px;

      & .results-item-content-conditions-plane-name {
        margin-top: 0;
        max-height: 22px;
        overflow: hidden;
        font: 800 21px/22px Inter;
        margin-right: 0;

        & .skeleton {
          width: 175px;
          height: 22px;
        }
      }

      & .results-item-content-conditions-plane-type, .results-item-content-conditions-plane-el  {
        margin-top: 4px;
        font: 500 9px/13px Inter;
        margin-right: 0;

        & .skeleton {
          width: 100px;
          height: 13px;
        }
      }

      & .results-item-content-conditions-plane-el {
        display: block;
      }

      & .results-item-content-conditions-plane-type-no-display-mobile {
        display: none;
      }

      & .results-item-content-conditions-nowarning {
        display: none;
      }

      & .results-item-content-conditions-warning {
        display: none;
      }

      & .results-item-content-conditions-directions {
        display: none;
      }

      & .results-item-content-conditions-mobile {
        display: flex;
        margin-top: 12px;
        margin-right: -30px;
        font: 400 14px/14px Inter;
        letter-spacing: -0.02em;

        & .results-item-content-conditions-mobile-price {
          & .skeleton {
            width: 65px;
            height: 15px;
          }
        }

        & .results-item-content-conditions-mobile-year, .results-item-content-conditions-mobile-seat, .results-item-content-conditions-mobile-flight-time {
          margin-left: 15px;
          color: #808080;

          & .skeleton {
            width: 40px;
            height: 15px;
          }
        }

        & .results-item-content-conditions-mobile-splitter, .results-item-content-conditions-mobile-flight-time {
          display: none;
        }
      }
    }

    & .results-item-content-options {
      margin-top: 0;

      & .results-item-content-options-main {

        & .results-item-content-options-main-favorites {
          margin: 0;
        }

        & .results-item-content-options-main-price {
          display: none;
        }
      }

      & .results-item-content-options-plane {
        display: none;
      }
    }
  }

  & .results-item-content-mobile-infoblock {
    display: flex;
    justify-content: space-between;
    font: 400 14px/14px Inter;
    letter-spacing: -0.02em;
    color: #999999;
    margin: 0 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F2F2;

    & .results-item-content-mobile-infoblock-date {

      & .skeleton {
        height: 14px;
        width: 200px;
      }
    }

    & .results-item-content-mobile-infoblock-price {
      color: #000000;

      & .skeleton {
        height: 14px;
        width: 80px;
      }
    }
  }

  & .results-item-content-there, .results-item-content-back {
    border: 0;

    & .results-item-content-conditions {

      & .results-item-content-conditions-mobile {

        & .results-item-content-conditions-mobile-year, .results-item-content-conditions-mobile-seat, .results-item-content-conditions-mobile-flight-time {
          margin-left: 0;
        }

        & .results-item-content-conditions-mobile-splitter, .results-item-content-conditions-mobile-flight-time {
          display: block;
          color: #808080;
        }
      }
    }
  }

  & .results-item-content-there {
    padding-bottom: 0;

    .results-item-content-conditions-mobile {

      .results-item-content-conditions-mobile-price {
        display: none;
      }
    }
  }

  & .results-item-content-back {

    & .results-item-content-mask {
      margin-left: 16px;
    }

    & .results-item-content-conditions-mobile {

      .results-item-content-conditions-mobile-price {
        display: none;
      }
    }
  }
}

@media (max-width: 1090px) {

  .flights .results .results-item {
    @include width-1090;
  }
}

@media (max-width: 900px) {

  .flights .results .results-item {
    @include width-900;
  }
}

@media (max-width: 760px) {

  .flights .results .results-item {
    @include width-760;
  }
}

@media (max-width: 700px) {

  .flights .results .results-item {
    @include width-700;
  }
}

.flights .results .flightinfo-item {

  @include width-1090;
  @include width-900;
  @include width-760;
  @include width-700;
  transform: skew(-3.5deg);
  height: 82px;
  width: 315px;
  margin-right: 20px;
  border-radius: 8px;
  border-width: 0;

  & .results-item-content {
    transform: skew(3.5deg);
    margin-left: 9px;
    margin-right: 9px;
    padding-top: 8px;
    padding-bottom: 8px;

    & .results-item-content-options .results-item-content-options-main .results-item-content-options-main-favorites {
      display: none;
    }
  }
}

.flights .results .flightinfo-back-item {
  height: auto;

  & .results-item-content-mobile-infoblock {
    transform: skew(3.5deg);
    border: 0;
    padding: 4px 0 10px 0;

    & .results-item-content-mobile-infoblock-date {
      display: none;
    }
  }
}
